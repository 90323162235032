// print label styles
.print-label-page {
    // section {
    //     display: none;
    // }
    .print-label-hero {
        display: block;
        background-color: transparent;
        padding: 0;
        color: #4b5a7e;
        > .container {
            padding: 0;
            max-width: 106rem;
            * {
                color: #4b5a7e;
            }
        }
        .print-label {
            font-size: 6px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            .details-row,
            p,
            address {
                font-size: 15px;
            }
            .h2 {
                font-size: 25px;
                color: #0045e6;
            }
            .h3 {
                font-size: 20px;
                color: #0045e6;
            }
            &__first {
                padding: 30px;
            }
            &__second {
                padding: 30px;
            }
        }
    }

    .header-minimal,
    .footer-main {
        display: none;
    }

    // .print-label {
    //     &__first {
    //         padding-left: 0;
    //         padding-top: 0;
    //         padding-bottom: 0;
    //     }
    //     &__second {
    //         padding-right: 0;
    //         padding-top: 0;
    //         padding-bottom: 0;
    //     }
    // }
}
/*------------------------------------------------------ LAYOUT - HIDE UNNECESSARY ELEMENTS HERE */

nav,
.enquiry-form,
.footer-main {
    display: none;
}

body {
    [data-scroll],
    [data-scroll] * {
        opacity: 1 !important;
        transform: none !important;
        animation: none !important;
        animation-play-state: paused !important;
    }

    .aside-image__images-wrap {
        display: block;
        .img-wrap-01,
        .img-wrap-02 {
            position: static;
            left: auto;
            right: auto;
            top: auto;
            bottom: auto;
        }
        .img-wrap-01 img,
        .img-wrap-02 img {
            position: static;
            height: auto;
        }
    }
}
